<template>
  <div class="SysParam">
    <simple-c-u-r-d
      search-url="/api/sysParam/list"
      add-or-update-url="/api/sysParam/addOrUpdate"
      delete-url="/api/sysParam/delete"
      :show-form="showForm"
      :model="addForm"
      :add-form-rules="addFormRules"
      is-pageable
      label-width="100px"
      name="param"
      :format-commit-data="formatCommitData"
    />
  </div>
</template>

<script>
import SimpleCURD from '@/components/simple/SimpleCURD'
export default {
  name: 'SysParam',
  components: { SimpleCURD },
  inject: ['formatCommitData'],
  data() {
    return {
      showForm: [
        { param: 'param', name: '参数名', maxlength: '100', search: true, clearable: true },
        { param: 'value', name: '参数值', maxlength: '200', clearable: true },
        { param: 'description', name: '备注', maxlength: '1000', clearable: true },
      ],
      addForm: { param: null, value: null, description: null },
      addFormRules: {
        param: [{ required: true, message: '请输入参数名', trigger: 'blur' }],
      }
    }
  },
}
</script>
